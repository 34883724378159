<template>
  <v-container fluid class="mt-8">
        <v-row>
            <v-col cols="6">
                <v-btn dark :loading="excelLoading">
                    <JsonExcel
                        name="PlaysmartUserList.xls"
                        :fetch="downoloadExcel"
                    >
                        {{$t('user.export_data')}}
                    </JsonExcel>
                </v-btn>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
                <v-btn dark @click="create">
                    {{$t('user.create')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                    :items="userRoleArr"
                    v-model="selctedUserRole"
                    :label="$t('user.role')"
                    dense
                    clearable
                />
            </v-col>
            <!-- <v-col cols="3">
                <v-select
                    :items="userStatusArr"
                    v-model="searchByStatus"
                    :label="$t('user.search_status')"
                    dense
                    clearable
                />
            </v-col> -->
            <v-col cols="4">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="searchByName"
                    :label="$t('user.search_name')"
                    dense
                    clearable
                />
            </v-col>
            <v-col cols="4">
                <v-text-field
                    prepend-icon="mdi-magnify"
                    v-model="searchByEmail"
                    :label="$t('user.search_email')"
                    dense
                    clearable
                />
            </v-col>
        </v-row>
        <v-data-table
            mobile-breakpoint=0
            class="elevation-4"
            :loading="loading"
            :headers="i18nHeader"
            :items="userList"
            sort-by="createdAt"
            :sort-desc="true"
            :options.sync="options"
            :server-items-length="totalUser"
            :footer-props="{'items-per-page-options': [5, 10, 20, 30, 50]}"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ (item.name)? item.name : '-' }} </td>
                    <td v-text="item.email" />
                    <td>{{ $t(showUserInfo(item.role, 'role'))}}</td>
                    <td>{{ $t(showUserInfo(item.gender, 'gender'))}}</td>
                    <td> {{$moment(item.createdAt).format('YYYY/MM/DD HH:mm')}}</td>
                    <td>{{ $t(showUserInfo(item.status, 'status'))}}</td>
                    <td>
                        <v-icon @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                    </td>
                </tr>
                </template>
        </v-data-table>
        <FormDialog ref="dialog"
            :userRoleArr="userRoleOptions"
            :genderArr="genderOptions"
            :categoryList="categoryList"
            :paymentMethodList="paymentMethodList"
            :merchantPlanList="merchantPlanList"
            :areasList="handledAreaList"
            :userStatusArr="userStatusArr"
            @refresh="getAllUser"
        />
        <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import FormDialog from "@/views/Users/UserDetailDialog"
import ConfirmDialog from "@/components/ConfirmDialog"
import JsonExcel from "vue-json-excel"
import api from '@/api'

export default {
    components:{
        FormDialog,
        ConfirmDialog,
        JsonExcel
    },
    mounted(){
        // this.getAllUser()
        this.getAllCategories()
        this.getAllPaymentMethod()
        this.getAllMerchantPlan()
        this.getAllAreas()
    },
    computed:{
        ...mapState(['lang']),
        i18nHeader(){
            let result = this.headers

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        userRoleOptions(){
            let result = this.userRoleArr

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        genderOptions(){
            let result = this.genderArr

            result.map( item => {
                item.text = this.$t(item.i18n)
            })

            return result
        },
        handledAreaList(){
            let result = []
            if(this.areasList && this.areasList != ''){
                let areaArr = this.areasList['area']
                let location = this.areasList['subArea']

                areaArr.map( item => {
                    result.push({
                        'region': item[(this.lang == 'tc')? 'nameZh' : 'nameEng'],
                        'location': location[item.alias]
                    })
                })
            }
            return result
        }
    },
    watch: {
        options: {
            handler () {
                this.getAllUser()
            }
        },
        selctedUserRole: {
            handler (){
                this.getAllUser()
            }
        },
        searchByEmail: {
            handler (){
                this.getAllUser()
            }
        },
        searchByName: {
            handler (){
                this.getAllUser()
            }
        },
        searchByStatus: {
            handler (){
                this.getAllUser()
            }
        },
    },
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllUser() {
            this.loading = true
            try {
                var {sortBy, sortDesc, page, itemsPerPage} = this.options
                let order = (sortDesc[0])? -1 : 1
                let params = {
                    page: page,
                    perPage: itemsPerPage,
                    sort: sortBy,
                    order: order,
                }

                if(this.selctedUserRole != '') Object.assign( params ,{role: this.selctedUserRole} )
                if(this.searchByName != '') Object.assign( params ,{name: this.searchByName} )
                // if(this.searchByStatus != '') Object.assign( params ,{status: this.searchByStatus} )
                if(this.searchByEmail != '') Object.assign( params ,{email: this.searchByEmail} )

                let result = await api.get('/users', {params})

                if(result){
                    this.userList = result.data.docs //user list
                    this.totalUser = result.data.totalDocs

                    if (sortBy.length === 1 || sortDesc.length === 1) {
                        this.userList = this.userList.sort((a, b) => {
                            const sortA = a[sortBy[0]]
                            const sortB = b[sortBy[0]]

                            if (sortDesc[0]) {
                                if (sortA < sortB) return 1
                                if (sortA > sortB) return -1
                                return 0
                            } else {
                                if (sortA < sortB) return -1
                                if (sortA > sortB) return 1
                                return 0
                            }
                        })
                    }
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
            this.loading = false
        },
        async getAllCategories() {
            try{
                let result = await api.get('/category')
                if(result) this.categoryList = result.data
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllPaymentMethod() {
            try{
                let result = await api.get('/paymentMethod')
                if(result) this.paymentMethodList = result.data
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllMerchantPlan() {
            try{
                let result = await api.get('/merchantPlan')

                if(result){
                    this.merchantPlanList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async getAllAreas() {
            try{
                let result = await api.get('/setting/areas')

                if(result){
                    this.areasList = result.data
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
        },
        async changeStatus(item, index){
            let action = await this.$refs.confirmDialog.open(
                {
                    title  : this.$t('user.update_status'),
                    content: this.$t('user.qa_update_status') + `"${item.name}"` + `${(item.status === 'active')? this.$t('user.qa_update_status_ban') : this.$t('user.qa_update_status_active')}?`,
                })
            if (action) {
                let result = false

                item.status = (item.status == 'active')? 'blocked' : 'active'

                try {
                    result = await api.patch('/users/' + item._id, item)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon   : 'mdi-close',
                    })
                }

                if (result) {
                    this.userList[index].status = item.status
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: this.$t('global_alert.success_update_status'),
                        icon   : 'mdi-check',
                    })
                }
            }
            this.getAllUser()
        },
        async downoloadExcel(){
            this.excelLoading = true
            let result = ''
            try {
                result = await api.get('/users/all')

                result = Object.values(result.data)

                result.map(item => {
                    item.picture = (item.picture && item.picture != null)? 'Y' : 'N'
                    item.createdAt = this.$moment(item.createdAt).format('YYYY/MM/DD HH:mm')
                    item.updatedAt = this.$moment(item.updatedAt).format('YYYY/MM/DD HH:mm')
                })
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon   : 'mdi-close',
                })
            }
            this.excelLoading = false
            return result;
        },
        create(){
            this.$refs.dialog.open('create')
        },
        edit(item){
            this.$refs.dialog.open('edit', item)
        },
        showUserInfo(value, type){
            let targetArray = []

            switch (type) {
                case 'status':
                    targetArray = this.userStatusArr
                    break;
                case 'gender':
                    targetArray = this.genderArr
                    break;
                case 'role':
                    targetArray = this.userRoleArr
                    break;
            }

            return targetArray.find( item => item.value == value ).i18n
        },
    },
    data: () => ({
        headers: [
            {i18n: 'common.user_name',value: 'name'},
            {i18n: 'common.email',value: 'email'},
            {i18n: 'user.role', value: 'role'},
            {i18n: 'user.gender', value: 'gender'},
            {i18n: 'common.created_at', value: 'createdAt'},
            {i18n: 'common.status', value: 'status'},
            {i18n: 'common.edit', value: '', sortable: false },
        ],
        userStatusArr:[
            {i18n: 'common.inactive',value: 'inactive'},
            {i18n: 'common.active',value: 'active'},
            {i18n: 'common.blocked',value: 'blocked'},
        ],
        userRoleArr: [
            {
                i18n:'common.admin',
                value:'admin'
            },
            {
                i18n:'common.user',
                value:'user'
            },
            {
                i18n:'common.merchant',
                value:'merchant'
            }
        ],
        genderArr: [
            {
                i18n:'common.genderM',
                value:'M'
            },
            {
                i18n:'common.genderF',
                value:'F'
            }
        ],
        json_meta: [
            [ { key: "charset", value: "utf-8", },],
        ],
        searchByName: '',
        searchByStatus: '',
        searchByEmail: '',
        selctedUserRole: '',
        userList:[],
        categoryList:[],
        paymentMethodList: [],
        merchantPlanList: [],
        areasList:[],
        loading: false,
        excelLoading: false,
        createUserFormValid: true,
        editUserFormValid: true,
        options: {},
        totalUser: 0
    })
}
</script>