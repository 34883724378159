<template>
    <v-container>
        <v-dialog v-model="dialog" persistent max-width="1200">
            <v-toolbar dark>
                <v-btn icon @click="close()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ $t('user.' + action) }}</v-toolbar-title>
            </v-toolbar>
            <v-card>
                <v-card-text>
                    <v-container>
                        <v-form ref="userForm" v-model="formValid" lazy-validation>
                            <v-row>
                                <v-col cols="12">
                                    <v-select v-model="userDetailObj.role" :items="userRoleArr" item-text="text"
                                        item-value="value" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('user.role')" outlined required />
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field v-model="userDetailObj.name" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('user.name')" />
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field v-model="userDetailObj.email" :label="$t('common.email')" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /.+@.+\..+/.test(v) || $t('common.valid_email')
                                    ]" :disabled="action != 'create'" />
                                </v-col>
                                <v-col cols="2">
                                    <v-select v-model="userDetailObj.gender" :items="genderArr" item-text="text"
                                        item-value="value" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('user.gender')" required />
                                </v-col>
                                <v-col cols="6" class="py-0" v-if="action == 'edit'">
                                    <v-select :items="userStatusOptions" v-model="userDetailObj.status"
                                        :label="$t('common.status')" item-text="text" item-value="value" />
                                </v-col>
                            </v-row>
                            <v-row v-if="action == 'create'">
                                <v-col cols="6">
                                    <v-text-field v-model="userDetailObj.password" :label="$t('user.password')" required
                                        :rules="[
                                            v => !!v || $t('common.required'),
                                            v => v.length > 5 || $t('common.valid_password')
                                        ]" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="confirmPW" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('user.confirm_pw')" />
                                </v-col>
                            </v-row>

                            <v-row v-if="userDetailObj.role == 'merchant' && action == 'create'">
                                <v-col cols="12">
                                    <v-text-field v-model="merchantObj.name" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('merchant.name')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="merchantObj.phone" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /^[0-9]{8}$/.test(v) || $t('common.valid_phone')
                                    ]" :label="$t('merchant.phone')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="merchantObj.email" :rules="[
                                        v => !!v || $t('common.required'),
                                        v => /.+@.+\..+/.test(v) || $t('common.valid_email')
                                    ]" :label="$t('merchant.email')" :disabled="action != 'create'" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select :items="merchantPlanList" v-model="merchantObj.subscription" item-value="_id"
                                        :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.subscription')" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select v-model="merchantObj.category" :items="categoryList" item-value="_id"
                                        :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'" :label="$t('merchant.categories')"
                                        :rules="[v => v.length > 0 || $t('common.required')]" />
                                </v-col>
                                <v-col cols="6">
                                    <v-menu v-model="endDateDateDialog" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="merchantObj.endDate" :label="$t('merchant.end_time')"
                                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" />
                                        </template>
                                        <v-date-picker v-model="merchantObj.endDate" @input="endDateDateDialog = false" />
                                    </v-menu>
                                </v-col>
                                <v-col cols="6">
                                    <multiselect v-model="merchantObj.area" :options="areasList" group-label="region"
                                        group-values="location" :multiple="false" :group-select="false"
                                        :placeholder="$t('common.area')" track-by="alias"
                                        :label="(lang == 'tc') ? 'nameZh' : 'nameEng'">
                                        <span slot="noResult">{{ $t('global_alert.area_no_result') }}</span>
                                    </multiselect>
                                </v-col>

                                <v-col cols="12">
                                    <v-textarea v-model="merchantObj.address" :label="$t('common.address')" rows="2" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select :items="paymentMethodList" v-model="merchantObj.paymentMethods"
                                        item-value="_id" :item-text="(lang == 'tc') ? 'nameZh' : 'nameEng'"
                                        :label="$t('merchant.payment_methods')" multiple
                                        :rules="[v => v.length > 0 || $t('common.required')]" />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field v-model="merchantObj.charge" :rules="[v => !!v || $t('common.required')]"
                                        :label="$t('merchant.charge')" />
                                </v-col>
                                <v-col cols="6" class="py-0">
                                    <v-text-field v-model="merchantObj.open" :rules="[
                                        v => !!v || $t('common.required')
                                    ]" :label="$t('merchant.open')" />
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field v-model="merchantObj.website" :label="$t('merchant.website')"
                                        :rules="[v => /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/.test(v) || $t('common.valid_url')]"
                                        hint="http://google.com" persistent-hint />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="merchantObj.slogan" :label="$t('merchant.slogan')" rows="2" />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="merchantObj.description" :label="$t('merchant.description')"
                                        rows="2" />
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea v-model="merchantObj.facilities" :label="$t('merchant.facilities')"
                                        rows="2" />
                                </v-col>

                                <v-col cols="12">
                                    <v-card>
                                        <v-card-title>{{ $t('merchant.image') }}</v-card-title>
                                        <v-card-text>
                                            <draggable v-if="merchantObj.image" v-model="merchantObj.image"
                                                @start="drag = true" @end="drag = false" tag="div" class="row"
                                                draggable=".draggableImage">
                                                <v-col v-for="(image, index) in merchantObj.image" :key="image._id"
                                                    class="d-flex child-flex draggableImage" cols="2" style="height:235px">
                                                    <v-hover>
                                                        <template v-slot:default="{ hover }">
                                                            <v-card>
                                                                <v-img max-height="150" v-if="image.large"
                                                                    :src="image.large.path" aspect-ratio="1"
                                                                    class="grey lighten-2">
                                                                    <template v-slot:placeholder>
                                                                        <v-row class="fill-height ma-0" align="center"
                                                                            justify="center">
                                                                            <v-progress-circular indeterminate
                                                                                color="grey lighten-5" />
                                                                        </v-row>
                                                                    </template>
                                                                </v-img>
                                                                <v-fade-transition>
                                                                    <v-overlay v-if="hover" absolute color="#036358">
                                                                        drag to re-odering
                                                                    </v-overlay>
                                                                </v-fade-transition>
                                                                <v-card-actions>
                                                                    <v-btn v-if="permissions == 'admin'" style="z-index:999"
                                                                        icon @click="copyImage(index)">
                                                                        <v-icon>mdi-content-copy</v-icon>
                                                                    </v-btn>
                                                                    <v-spacer />
                                                                    <v-btn style="z-index:999" icon
                                                                        @click="removeImage(index)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                        </template>
                                                    </v-hover>
                                                </v-col>
                                            </draggable>
                                            <v-col cols="12" key="newImage">
                                                <v-card style="height:100%;">
                                                    <v-container>
                                                        <file-pond name="upload_image" ref="pond"
                                                            :label-idle="$t('img_label')"
                                                            accepted-file-types="image/jpeg, image/png"
                                                            :files="uploadImageFile" allow-multiple="false"
                                                            max-file-size="5MB" @processfile="removeFile" />
                                                    </v-container>
                                                </v-card>
                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" class="py-0" v-if="action == 'edit'">
                                    <v-switch v-model="merchantObj.status" :label="showStatus(merchantObj.status)"
                                        true-value="active" false-value="blocked" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" v-if="action == 'edit'">
                                    <v-text-field :value="$moment(userDetailObj.createdAt).format('YYYY/MM/DD hh:mm')"
                                        disabled :label="$t('common.created_at')" />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" @click="save(action)">{{ $t('common.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import api from '@/api'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import draggable from 'vuedraggable'

// Import Vue FilePond
import vueFilePond, { setOptions } from 'vue-filepond';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize);

export default {
    components: {
        FilePond,
        Multiselect,
        draggable
    },
    mounted() {
        let self = this
        this.setOptions({
            server: {
                process: async function (fieldName, files, metadata, load, error, progress, abort) {
                    try {
                        let formData = new FormData()
                        formData.append('file', files)

                        let result = await api.post('/image', formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })

                        if (result) {
                            // self.merchantObj.image = result.data._id
                            self.merchantObj.image.push(result.data);
                        }
                        load(true)
                    } catch (err) {
                        console.log(err.message);
                        abort(true)
                    }
                }
            },
            imageResizeMode: 'contain',
        })
    },
    computed: {
        ...mapState(['lang']),
        userStatusOptions() {
            let result = this.userStatusArr

            result.map(item => {
                item.text = this.$t(item.i18n)
            })

            return result
        }
    },
    props: ['userRoleArr', 'genderArr', 'categoryList', 'paymentMethodList', 'merchantPlanList', 'areasList', 'userStatusArr'],
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async open(action, item) {
            if (action == 'edit') {
                let result = false

                try {
                    result = await api.get('/users/' + item._id)
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                if (result) this.userDetailObj = result.data
            }

            this.action = action
            this.dialog = true
        },
        async save() {
            let validated = this.$refs.userForm.validate()

            if (validated) {
                let result = false
                this.loading = true

                if (this.userDetailObj.role == 'merchant' && this.action == 'create') {
                    if (this.merchantObj.image == null) {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_upload_img'),
                            icon: 'mdi-close',
                        })
                        return false
                    } else if (this.merchantObj.area == '') {
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t('global_alert.warning_area'),
                            icon: 'mdi-close',
                        })
                        return false
                    }

                    this.merchantObj.area = this.merchantObj.area['alias']
                    this.merchantObj.endDate = this.$moment(this.merchantObj.endDate).toISOString()
                    this.userDetailObj['merchant'] = this.merchantObj
                }

                try {
                    if (this.action == 'create') {
                        if (this.userDetailObj.password === this.confirmPW) {
                            result = await api.post('/users', this.userDetailObj)
                        } else {
                            this.PUSH_GLOBAL_SNACK_MESSAGES({
                                message: this.$t('global_alert.warning_confirm_password_not_match'),
                                icon: 'mdi-close',
                            })
                        }
                    } else {
                        result = await api.patch('/users/' + this.userDetailObj._id, this.userDetailObj)
                    }

                    if (result) {
                        let targetI18n = (this.action === 'edit') ? 'global_alert.success_update_user' : 'global_alert.success_create_user'
                        this.resetForm()
                        this.dialog = false
                        this.PUSH_GLOBAL_SNACK_MESSAGES({
                            message: this.$t(targetI18n),
                            icon: 'mdi-check',
                        })
                    }
                } catch (error) {
                    this.PUSH_GLOBAL_SNACK_MESSAGES({
                        message: (error.response.data.code == 409) ? this.$t('global_alert.warning_user') : error.response.data.message,
                        icon: 'mdi-close',
                    })
                }

                this.loading = false
            }
        },
        formatTime(time) {
            let resultTime = time

            if (time) {
                resultTime = (time && this.$moment(time, 'HH:mm', true).isValid()) ? this.$moment(time, 'HH:mm').format('HHmm') : this.$moment(time, 'HHmm').format('HH:mm')
            }

            return resultTime
        },
        showStatus(status) {
            return (status == 'active') ? this.$t('common.enabled') : this.$t('common.disabled')
        },
        removeImage(index) {
            this.merchantObj.image.splice(index, 1)
        },
        copyImage(index) {
            var toCopy = this.merchantObj.image[index]["small"]["path"];
            navigator.clipboard.writeText(toCopy);
        },
        close() {
            this.resetForm()
            this.dialog = false
        },
        resetForm() {
            this.$emit('refresh')
            this.confirmPW = ''
            this.uploadImageFile = []
            this.userDetailObj = {
                email: '',
                password: '',
                name: '',
                role: '',
                status: 'active',
                merchant: {}
            }
            this.merchantObj = {
                name: '',
                category: '',
                phone: '', //  /^[0-9]{8}$/
                email: '',
                area: '',
                address: '',
                charge: '$100/hour',
                endDate: null,
                open: null,
                close: null,
                website: 'http://',
                slogan: '',
                description: '',
                facilities: '',
                paymentMethods: '',
                subscription: '',
                image: [],
                status: 'active',
            }
        },
        removeFile(error, file) {
            this.$refs.pond.removeFile(file)
        },
    },
    data: () => ({
        setOptions,
        dialog: false,
        userDetailObj: {
            email: '',
            password: '',
            name: '',
            role: '',
            status: 'active', //blocked
            merchant: {},
        },
        merchantObj: {
            name: '',
            category: '',
            phone: '', //  /^[0-9]{8}$/
            email: '',
            area: '',
            address: '',
            charge: '$100/hour',
            endDate: null,
            open: null,
            close: null,
            website: 'http://',
            slogan: '',
            description: '',
            facilities: '',
            paymentMethods: '',
            subscription: '',
            image: [],
            // status: 'active',
        },
        uploadImageFile: [],
        action: 'edit',
        confirmPW: '',
        formValid: true,
        endDateDateDialog: false
    })
}
</script>